<template>
  <div>
    <div class="d-flex flex-row align-items-center justify-content-center">
      <div v-if="hasNullableCategory">
        - {{ $currency }}
      </div>
      <template v-else>
        <div
          v-if="isVisitor"
        >
          {{ item.toPay }}
        </div>
        <discount-icon
          v-if="item.participant !== null"
          :item="item"
          @on:discount-icon:click="$emit('on:discount-icon:click', item)"
          @on:undiscount-icon:click="$emit('on:undiscount-icon:click', item)"
        />
      </template>
    </div>
  </div>
</template>
<script>

import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  props: ['item'],
  components: {
    DiscountIcon: () => import('@custom/booking/DiscountIcon')
  },
  computed: {
    isVisitor() {
      return this.item.participant === null
    },
    hasNullableCategory() {
      if (isNotUndefinedAndNotNull(this.item.hasLeisure)) {
        return this.item.hasLeisure && this.item.category === null;
      }

      return false;
    }
  },
}
</script>

<style lang="scss" scoped>
.discount-amount {
  color: #EE3C31;
}

.discount {
  text-align: center;
}
</style>
